let nodeDomain, strapiDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.evervent.live";
  strapiDomain = "https://strapi.unison.tech";
  phpDomain = "https://devapi.evervent.live";
  frontendDomain = "https://devunison.evervent.live";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.unisoninsurance.net";
  strapiDomain = "https://strapi.unisoninsurance.net";
  phpDomain = "https://stgapi.unisoninsurance.net";
  frontendDomain = "https://stgv2.unisoninsurance.net";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.unisoninsurance.net";
  strapiDomain = "https://strapi.unisoninsurance.net";
  phpDomain = "https://apiv1.unisoninsurance.net";
  frontendDomain = "https://bima.unisoninsurance.net";
} else {
  // Default to dev environment
  nodeDomain = "https://devnodeapi.evervent.live";
  strapiDomain = "https://strapi.unison.tech";
  phpDomain = "https://devunison.evervent.live";
  frontendDomain = "";
}

export const NODE_DOMAIN = nodeDomain;
export const STRAPI_DOMAIN = strapiDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
